// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-birthplace-js": () => import("./../../../src/pages/birthplace.js" /* webpackChunkName: "component---src-pages-birthplace-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-fixtures-js": () => import("./../../../src/pages/fixtures.js" /* webpackChunkName: "component---src-pages-fixtures-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-players-js": () => import("./../../../src/pages/players.js" /* webpackChunkName: "component---src-pages-players-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-ranking-js": () => import("./../../../src/pages/ranking.js" /* webpackChunkName: "component---src-pages-ranking-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-sns-js": () => import("./../../../src/pages/sns.js" /* webpackChunkName: "component---src-pages-sns-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-venue-js": () => import("./../../../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */),
  "component---src-templates-player-page-js": () => import("./../../../src/templates/player-page.js" /* webpackChunkName: "component---src-templates-player-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

